@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* Scrollbar width for vertical and horizontal */
::-webkit-scrollbar {
  width: 5px; /* For vertical scrollbar */
  height: 5px; /* For horizontal scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}
.addsubadmin::-webkit-scrollbar{
  width:0 !important;
  height: 0;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #0000003D;
  border-radius: 30px;
}
.MuiPaper-root::-webkit-scrollbar{
  width: none;
}
* {
  font-family: "Poppins", sans-serif !important;
  /* font-style: normal !important; */
  box-sizing: border-box;
  padding: 0%;
  margin: 0%;
}

.addOfferDescription{
  border: 1px solid rgba(110, 129, 141, 1);
  background:'linear-gradient(to left, #BAE6FD80, #F0F9FF80)',
}
.addOfferDescription .ql-toolbar{
  border: none;
  padding: 16px 16px;
  background: rgba(209, 209, 209, 0.2);
}
.addOfferDescription .ql-toolbar .ql-formats button{
  width: 30px;
  height: 30px;
}
.addOfferDescription .ql-container {
  border: none;
}
.addOfferDescription .ql-container .ql-editor {
  min-height: 200px;
  overflow-y: auto;
  height: 200px;
}
.termsCondtionContent{
  border: none;
}
.termsCondtionContent .ql-toolbar{
  border: none;
  padding: 16px 16px;
  background: rgba(209, 209, 209, 0.2);
}
.termsCondtionContent .ql-toolbar .ql-formats button{
  width: 30px;
  height: 30px;
}
.termsCondtionContent .ql-container {
  border: none;
}
.termsCondtionContent .ql-container .ql-editor {
  min-height: 200px;
  overflow-y: auto;
  height: 200px;
}


.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  display: block;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
  color: #007bff;
  border: 1px solid #007bff;
}

.pagination a:hover {
  background-color: #007bff;
  color: white;
}

.pagination .active a {
  background-color: #007bff;
  color: white;
}

.pagination .disabled a {
  color: #ccc;
  border-color: #ccc;
  pointer-events: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.inputClassCountry {
  padding: 20px 20px 20px 20px !important;
  width: 100% !important;
  border: none;
  border-radius: 8px !important;
  padding-left: 60px !important;
  background: #F0F0F0 !important;
  height: 56px !important;
  
}
.inputClassCountry.error {
  border: 1px solid red;
}
.inputClassCountry:focus {
  outline: none !important;
  box-shadow: none !important;
}
.flag-dropdown {
  background-color: transparent !important;
  border: 0px !important;
}
.flag-dropdown .selected-flag {
  padding: 0px !important;
  width: 60px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.flag-dropdown .selected-flag:hover {
  background-color: transparent !important;
}